import { useState } from 'react';
import qs from 'qs';

import trpc from '../../client/trpc';
import { getApiCache } from '../apiCache';
import { isDevEnv } from '../../client/helpers';
import {
  ClientError,
  LoadedDataQuery,
  ProcedureNameQuery,
  ProcedureQueryParams
} from '../../client/trpc/types';

type UseTrpcGetOutput<T extends ProcedureNameQuery> = {
  data?: LoadedDataQuery<T>;
  error?: Error | null;
  loading?: boolean;
};

export default function useTrpcGet<T extends ProcedureNameQuery>(
  api: T,
  params?: ProcedureQueryParams<T>[0]
): UseTrpcGetOutput<T> {
  const [data, setData] = useState<LoadedDataQuery<T>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ClientError>();
  const key = `${api as string}:${qs.stringify(params)}`;
  const cache = getApiCache();
  let cacheItem = cache[key];

  if (!cacheItem) {
    setLoading(true);
    const promise = trpc[api].query.apply(null, [params]);
    //Creating a new cache entry for previously unknown request
    cacheItem = cache[key] = { promise };
    promise
      .then((res: typeof data) => {
        cacheItem.data = res;
        setData(res);
      })
      .catch((err: ClientError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err.message}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  } else if (cacheItem.data && cacheItem.data !== data) {
    setData(cacheItem.data);
  }

  return {
    data,
    loading,
    error
  };
}
