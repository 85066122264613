import { useState } from 'react';
import { ApiError, apiPost } from '../services/api';
import { isDevEnv } from '../../client/helpers';

interface UseApiPostInput<Req = any> {
  apiUrl: string;
  params?: Req;
}

interface UseApiPostResult<Res = any, Req = any> {
  loading?: boolean;
  error?: ApiError<Res, Req>;
  data?: Res;
  execute?: () => void;
}

//TODO remove once the /login endpoint is implemented in tRPC
export default function useApiPost<Res = any, Req = any>({
  apiUrl,
  params
}: UseApiPostInput): UseApiPostResult<Res, Req> {
  const [data, setData] = useState<Res>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ApiError>();
  const execute: UseApiPostResult<Res, Req>['execute'] = () => {
    setLoading(true);
    apiPost<Res>(apiUrl, params)
      .then((res) => {
        setData(res.data);
      })
      .catch((err: ApiError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return {
    loading,
    error,
    data,
    execute
  };
}
