import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import BoxForm from '../BoxForm/BoxForm';
import { BoxFormData, BoxFormProps } from '../BoxForm/BoxForm.types';
import getUpdateErrorMessage from '../utils/getUpdateErrorMessage';
import Button from '../../../../../ui/components/Button/Button';
import useTrpcMutation from '../../../../../../api/hooks/useTrpcMutation';
import { BoxCreateOverlayProps } from './BoxCreateOverlay.types';
import styles from './BoxCreateOverlay.module.css';

export default function BoxCreateOverlay({
  onClose,
  onCloseAfterCreate
}: BoxCreateOverlayProps): JSX.Element {
  const [formData, setFormData] = useState<BoxFormData>({ title: '' });
  const [errorMsg, setErrorMsg] = useState<string>();
  const [isSuccess, setIsSuccess] = useState(false);

  const {
    execute,
    loading,
    data: response,
    error
  } = useTrpcMutation('addBox', {
    title: formData.title
  });

  useEffect(() => {
    if (response?.success && response.box) {
      setIsSuccess(true);
    }
  }, [response?.box, response?.success]);

  useEffect(() => {
    if (error) {
      setErrorMsg(getUpdateErrorMessage(error?.data?.httpStatus));
    }
  }, [error]);

  const onChange: BoxFormProps['onChange'] = (data): void => {
    setFormData({ ...formData, ...data });
    setErrorMsg(undefined);
  };
  const onSubmit = (): void => {
    if (!formData.title) {
      setErrorMsg('Need content');
      return;
    }
    execute();
  };

  return (
    <div className={styles.overlay} onClick={onClose}>
      <div
        className={classnames(styles.modal, isSuccess && styles.modalSuccess)}
        onClick={(e): void => e.stopPropagation()}
      >
        {isSuccess ? (
          <>
            <div className={styles.success}>Successfully created!</div>
            <div>
              <Button onClick={onCloseAfterCreate}>Close and refresh</Button>
            </div>
          </>
        ) : (
          <>
            <h1 className={styles.title}>Add box</h1>
            <BoxForm
              data={formData}
              loading={loading}
              formClassName={styles.form}
              errorMsg={errorMsg}
              onChange={onChange}
              onCancel={onClose}
              onSubmit={onSubmit}
            />
          </>
        )}
      </div>
    </div>
  );
}
