import { GlobalContext, PublicUser } from '../types';
import useTrpcGet from '../../api/hooks/useTrpcGet';

export const useGlobalRequest = (): GlobalContext => {
  const { data } = useTrpcGet('user');

  return {
    user: data?.user?.name ? (data.user as PublicUser) : undefined
  };
};
