import { useState } from 'react';

import trpc from '../../client/trpc';
import { isDevEnv } from '../../client/helpers';
import {
  ClientError,
  LoadedDataMutate,
  ProcedureMutateParams,
  ProcedureNameMutate
} from '../../client/trpc/types';

type UseTrpcMutationOutput<T extends ProcedureNameMutate> = {
  data?: LoadedDataMutate<T>;
  error?: ClientError | null;
  loading?: boolean;
  execute?: () => void;
};

export default function useTrpcMutation<T extends ProcedureNameMutate>(
  api: T,
  params?: ProcedureMutateParams<T>[0]
): UseTrpcMutationOutput<T> {
  const [data, setData] = useState<LoadedDataMutate<T>>();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<ClientError>();

  const execute: UseTrpcMutationOutput<T>['execute'] = () => {
    setLoading(true);
    trpc[api].mutate
      .apply(null, [params])
      .then((res: typeof data) => {
        setData(res);
      })
      .catch((err: ClientError) => {
        if (isDevEnv()) {
          //TODO better handle errors in prod and dev
          console.error(`Error: ${err.message}`);
        }
        setError(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return {
    data,
    loading,
    error,
    execute
  };
}
