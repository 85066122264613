import { BoxServer } from '../types';
import useTrpcGet from '../../../../api/hooks/useTrpcGet';
import { Box } from '../../../../server/types';

interface LoadBoxesResponse {
  boxes: BoxServer[];
}

interface LoadBoxesRequest {
  force?: boolean;
  limit?: number;
  offset?: number;
}

interface UseDataResult extends LoadBoxesResponse {
  loading?: boolean;
}

export const useData = (params?: LoadBoxesRequest): UseDataResult => {
  const { data, error, loading } = useTrpcGet('box', params);

  if (error) {
    //TODO
  }

  return { boxes: data as Box[], loading };
};
