import { createTRPCClient, httpBatchLink } from '@trpc/client';
import { AppRouter } from '../../server/trpc/api';
import { getBaseURL } from '../../api/services/api';
import { isSsr } from '../helpers';
import { HTTP_HEADER_SSID } from '../../global/constants';
import globalInitRequestData from '../../global/helpers/globalInitRequestData';

const trpc = createTRPCClient<AppRouter>({
  links: [
    httpBatchLink({
      url: getBaseURL(true),
      headers() {
        const initRequestData = globalInitRequestData.getData();
        //Requests made during SSR don't send any cookies, so things based on a session won't work (e.g. user authentication status)
        // that's why we receive it from the server as `ssid` and send back as a header
        return isSsr() && initRequestData.ssid
          ? { [HTTP_HEADER_SSID]: initRequestData.ssid }
          : {};
      }
    })
  ],
  transformer: undefined
});

export default trpc;
