export default function getUpdateErrorMessage(errorCode: number): string {
  switch (errorCode) {
    case 401:
    case 403:
      return 'Access denied';
    case 404:
      return 'Box not found';
    case 500:
    case 505:
      return 'Server error';
    default:
      return 'Error occurred while saving';
  }
}
