import axios, { AxiosResponse, AxiosError } from 'axios';
import { isSsr } from '../../client/helpers';

export type ApiResponse<T = any, D = any> = AxiosResponse<T, D>;
export type ApiError<T = any, D = any> = AxiosError<T, D>;

export const getBaseURL = (isTrpc?: boolean) => {
  const apiUri = isTrpc
    ? process.env.RAZZLE_API_TRPC_BASE_URI
    : process.env.RAZZLE_API_BASE_URI;
  if (isSsr()) {
    const host = process.env.RAZZLE_API_HOST;
    return process.env.NODE_ENV === 'development' && process.env.PORT
      ? `${host}:${process.env.PORT}${apiUri}`
      : `${host}${apiUri}`;
  } else {
    return apiUri;
  }
};

const axiosInstance = axios.create({
  baseURL: getBaseURL(),
  withCredentials: true
});

export const apiGet = axiosInstance.get;

export const apiPost = axiosInstance.post;

export const apiPut = axiosInstance.put;

export const apiDelete = axiosInstance.delete;
