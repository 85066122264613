import React, { useState, useEffect } from 'react';
import BoxData from '../../GridBoxes/BoxData';
import BoxForm from '../BoxForm/BoxForm';
import { BoxFormProps } from '../BoxForm/BoxForm.types';
import getUpdateErrorMessage from '../utils/getUpdateErrorMessage';
import Button from '../../../../../ui/components/Button/Button';
import useTrpcMutation from '../../../../../../api/hooks/useTrpcMutation';
import styles from './BoxEditForm.module.css';

interface BoxEditFormProps {
  data: BoxData['data'];
  onUpdate?: (box: BoxData['data']) => void;
  onCancel?: () => void;
  onDelete?: () => void;
}

type BoxEditFormState = Pick<BoxEditFormProps['data'], 'title'>;

function BoxEditForm({
  data,
  onCancel,
  onUpdate,
  onDelete
}: BoxEditFormProps): JSX.Element {
  const [state, setState] = useState<BoxEditFormState>(data);
  const [isDeleted, setIsDeleted] = useState(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const {
    execute: executeSave,
    loading: loadingSave,
    data: responseSave,
    error: errorSave
  } = useTrpcMutation('editBox', {
    id: data.id,
    title: state.title
  });

  const {
    execute: executeDelete,
    loading: loadingDelete,
    data: responseDelete,
    error: errorDelete
  } = useTrpcMutation('deleteBox', {
    id: data.id
  });
  const onChange: BoxFormProps['onChange'] = (data): void => {
    setState({
      ...state,
      ...data
    });
    setErrorMsg(undefined);
  };
  useEffect(() => {
    if (responseSave?.success && responseSave.box) {
      onUpdate(responseSave.box as BoxData['data']);
    }
  }, [onUpdate, responseSave?.box, responseSave?.success]);

  useEffect(() => {
    if (responseDelete?.success) {
      setIsDeleted(true);
    }
  }, [responseDelete?.success]);

  useEffect(() => {
    if (errorSave) {
      setErrorMsg(getUpdateErrorMessage(errorSave.data.httpStatus));
    }
    if (errorDelete) {
      setErrorMsg(getUpdateErrorMessage(errorDelete?.data.httpStatus));
    }
  }, [errorDelete, errorSave]);

  const onSubmit = (): void => {
    executeSave();
  };

  const onDeleteHandler = (): void => {
    executeDelete();
  };

  if (isDeleted) {
    return (
      <div className={styles.deletedSuccess}>
        <h1 className={styles.deletedSuccessTitle}>Box deleted</h1>
        <Button onClick={onDelete}>Close and refresh</Button>
      </div>
    );
  }

  return (
    <BoxForm
      data={state}
      loading={loadingSave || loadingDelete}
      isDeletable
      errorMsg={errorMsg}
      onChange={onChange}
      onSubmit={onSubmit}
      onDelete={onDeleteHandler}
      onCancel={onCancel}
    />
  );
}

export default BoxEditForm;
